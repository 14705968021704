import React, { Component } from 'react';
import { Label, Progress } from 'semantic-ui-react';

interface ProgressBarProps {
  percent: number;
}

class ProgressBar extends Component<ProgressBarProps> {
  render() {
    const { percent } = this.props;

    const progressBarStyle = {
        backgroundColor: 'green',
        border: '2px solid gold',
       // height: '10px', // Adjust the height as needed
      };

    const zeroBarStyle = {
        minwidth: '0px',
        basic: true
    };

    if(percent < 100 && percent > 0)
    {
        return (
            <div className="progress-bar-container">
              <Progress percent={percent} indicating size='tiny' min={0} />
            </div>
          );
    }

    else if(percent == 0)
    {
        return (
            <div className="progress-bar-container" >
              <Progress percent={100} size='tiny' style={zeroBarStyle}/>
            </div>
          );
    }

    else
    {
        return (
            <div className="progress-bar-container">
            {/* <Label ribbon  color='green'>
                Limit Break
            </Label> */}
              <Progress percent={100} size='tiny' indicating min={0} style={progressBarStyle}/>
            </div>
          );
    }

  }
}

export default ProgressBar;
