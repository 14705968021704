import { observer } from "mobx-react-lite";
import { Grid } from "semantic-ui-react";
import BattleUnitHeader from "./BattleUnitHeader";
import BattleUnitDashboard from "./BattleUnitDashboard";
import { BattleUnit } from "../../app/models/battleunit";

interface Props {
    battleunites: BattleUnit[];

}

export default observer ( function BattleUnitPage({battleunites}: Props) {
    return(
        <Grid>
            <Grid.Column width={16}>
                {
                    <>
                    <BattleUnitHeader />
                    <BattleUnitDashboard battleunites={battleunites} />
                    </>
                }
            </Grid.Column>
        </Grid>
    )
})