import { BattleUnit } from "../models/battleunit";
import { makeAutoObservable, runInAction } from "mobx";

export default class BattleUnitStore {
    BattleUnitList: BattleUnit[] = [];
    

    constructor() {
        makeAutoObservable(this);
    }

    setBattleUnitList = (battleUnits: BattleUnit[]) => {
        this.BattleUnitList = battleUnits;
    }
}