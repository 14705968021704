import { observer } from "mobx-react-lite";
import { Card, Grid, List, Tab } from "semantic-ui-react";
import { BattleUnit } from "../../app/models/battleunit";
import BattleUnitCard from "./BattleUnitCard";
import BattleUnitFilters from "./BattleUnitFilters";
import { useStore } from "../../app/stores/store";

interface Props{
    battleunites: BattleUnit[];
    
}

export default observer (function BattleUnitDashboard({battleunites}: Props) {
      const {battleUnitStore: {BattleUnitList, setBattleUnitList}} = useStore();
    return(
        // <Tab
        //     menu={{fluid: true, vertical: true}}
        //     menuPosition="right"
        //     panes={}
        // />

        <Grid>
            <Grid.Column width={10}>
                <List>
                        <Card.Group  >
                    {BattleUnitList.map((battleunit) => (
                        <BattleUnitCard key={battleunit.id} battleunit={battleunit} />
                    ))}
                </Card.Group>
                </List>
            </Grid.Column>
            <Grid.Column width={6}>
                <BattleUnitFilters battleunites={battleunites}/>
            </Grid.Column>
            
        </Grid>

    )
})