import { createContext, useContext } from "react";
import BattleUnitStore from "./BattleUnitStore";

interface Store {
    battleUnitStore: BattleUnitStore;

}

export const store: Store = {
    battleUnitStore: new BattleUnitStore(),

}


export const StroeContext = createContext(store);

export function useStore() {
    return useContext(StroeContext)
}