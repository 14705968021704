import { observer } from "mobx-react-lite";
import { BattleUnit } from "../../app/models/battleunit";
import { Card, Grid, Icon, Image, Label, Progress } from "semantic-ui-react";
import ProgressBar from "../../app/common/MyProgressBar";

interface Props {
    battleunit: BattleUnit;
}

const MAX_HP = 100;
const MAX_MP = 100;
const Max_ATK = 50;
const MAX_DEF = 50;
const MAX_MAG = 75;


//Might want to link card with React-Router-Dom as={Link} to={`/profiles/${profile.username}`}> <- exmaple
export default observer( function BattleUnitCard({battleunit}: Props) {

    let unitimagestring = true;

    if(battleunit.image == "NULL" || battleunit.image == null)
    {
        unitimagestring = false;
    }
    // {console.log(Math.floor(((battleunit.hp/MAX_HP)*100)))}
    return(
        <Card>
            <Image src={unitimagestring ? battleunit.image : '/assets/battleunites/missing.png'} /> 
            { battleunit.rank == 'Elite' &&
            <Label ribbon  color='grey'>
                Elite
            </Label> }
            { battleunit.rank == 'Master' &&
            <Label ribbon  color='black'>
                Master
            </Label> }
                <Card.Content>
                    <Card.Header>{battleunit.name}</Card.Header>
                    <Card.Description>
                        HP : ({battleunit.hp} / {MAX_HP < battleunit.hp ? battleunit.hp : MAX_HP})
                        {/* <div className="ui indicating tiny progress" data-percent="11" id="hpBar"><div className="bar" style={{width:11}}></div></div> */}
                            <ProgressBar percent={Math.floor(((battleunit.hp/MAX_HP)*100))} />
                        MP : ({battleunit.mp} / {MAX_MP < battleunit.mp ? battleunit.mp : MAX_MP})
                            <ProgressBar percent={Math.floor(((battleunit.mp/MAX_MP)*100))} />
                        ATK : ({battleunit.atk} / {Max_ATK < battleunit.atk ? battleunit.atk : Max_ATK})
                            <ProgressBar percent={Math.floor(((battleunit.atk/Max_ATK)* 100))} />
                        DEF : ({battleunit.def} / {MAX_DEF < battleunit.def ? battleunit.def : MAX_DEF})
                            <ProgressBar percent={Math.floor(((battleunit.def/MAX_DEF)* 100))} />
                        MAG : ({battleunit.mag} / {MAX_MAG < battleunit.mag ? battleunit.mag : MAX_MAG})
                            <ProgressBar percent={Math.floor(((battleunit.mag/MAX_MAG)* 100))} />
                        
                    </Card.Description>
                </Card.Content>
                <Card.Content >
                    Speed : {battleunit.speed}ft. | {battleunit.movement} <br />
                    Type : {battleunit.type} <br />
                    cost : {battleunit.cost}gp
                </Card.Content>
        </Card>
    )
})