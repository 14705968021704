import { observer } from "mobx-react-lite";
import { Grid, Item, Segment, Statistic } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";

export default observer( function Battleunitheader() {
    const {battleUnitStore: {BattleUnitList, setBattleUnitList}} = useStore();
    return(
        <Segment>
            <Grid>
                <Grid.Column width={12}>
                    <Item.Group>
                        <h1>Battle Unit List</h1>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Statistic.Group widths={4}>
                        <Statistic label="Units count" value={BattleUnitList.length} />
                    </Statistic.Group>
                </Grid.Column>
            </Grid>
        </Segment>
    )
})