import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Container, Header, List } from 'semantic-ui-react';
import BattleUnitPage from '../../features/battleunites/BattleUnitPage';
import { BattleUnit } from '../models/battleunit';
import NavBar from './NavBar';
import { useStore } from '../stores/store';

function App() {
  const [battleunites, setBattleunites] = useState<BattleUnit[]>([]); //this is pretty much just making a var thatwe can store and set.
  const {battleUnitStore: {BattleUnitList, setBattleUnitList}} = useStore();

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    axios.get<BattleUnit[]>('battleunit')
      .then(response => {
        //console.log(response);
        setBattleunites(response.data);
        setBattleUnitList(response.data);
      })
  }, []) //adding this [] to have it only do this once 

  return (
    <>
      <NavBar />
        <Container style={{marginTop: '7em'}}>
          <BattleUnitPage battleunites={battleunites} />
        </Container>

        

    </>
  );
}

export default App;
