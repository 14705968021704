import { observer } from "mobx-react-lite"
import { Formik, useField } from "formik";
import { useState, Component  } from "react";
import { Button, Card, Dropdown, Form, Grid, Item, Segment, Select } from "semantic-ui-react"
import MySelectInput from "../../app/common/MySelection";
import { BattleUnitFilter } from "../../app/models/battleunitFilter";
import { BattleUnit } from "../../app/models/battleunit";
import axios from "axios";
import { useStore } from "../../app/stores/store";

interface Props{
    battleunites: BattleUnit[];
}

const UnitTypes = [
    { key: 'Fighter', value: 'Fighter', text: 'Fighter' },
    { key: 'Ranged', value: 'Ranged', text: 'Ranged' },
    { key: 'Mounted', value: 'Mounted', text: 'Mounted' },
    { key: 'Mage', value: 'Mage', text: 'Mage' },
  ];

  const OrderBySet = [
    { key: 'hp', value: 'hp', text: 'HP' },
    { key: 'mp', value: 'mp', text: 'MP' },
    { key: 'atk', value: 'atk', text: 'ATK' },
    { key: 'def', value: 'def', text: 'DEF' },
    { key: 'mag', value: 'mag', text: 'MAG' },
    { key: 'cost', value: 'cost', text: 'Cost' },
    { key: 'speed', value: 'speed', text: 'Speed' }
  ];



  export default observer( function BattleUnitFilters({battleunites}: Props) {
    const {battleUnitStore: {BattleUnitList, setBattleUnitList}} = useStore();
    // const battleUnitFilter: BattleUnitFilter = {
    //     Unittype: '',
    //     ordertype: ''
    // }

    const [battleUnitFilter, setBattleunitFilter] = useState({
        Unittype: "",
        ordertype: ""
    })

    const [response, setResponse] = useState(null);

    const clearFilter = async () => {
        const response = await axios.get('battleunit');
        await setResponse(response.data);
        setBattleUnitList(response.data);
    }

    const applyFilter = async (_battleUnitFilter: BattleUnitFilter) => {
        try {
            //console.log(_battleUnitFilter)

            // if( _battleUnitFilter.Unittype != "" && _battleUnitFilter.ordertype != "")
            // {
                const response = await axios.get('battleunit', {
                    params: {
                    unittype: _battleUnitFilter.Unittype,
                    orderby: _battleUnitFilter.ordertype
                    }
                  });
                  await setResponse(response.data);
            // }
            // else if(_battleUnitFilter.Unittype == "" && _battleUnitFilter.ordertype != "")
            // {
            //     const response = await axios.get('battleunit', {
            //         params: {
            //         orderby: _battleUnitFilter.ordertype
            //         }
            //       });
            //       await setResponse(response.data);
            // }
            // else if(_battleUnitFilter.Unittype != "" && _battleUnitFilter.ordertype == "")
            // {
            //     const response = await axios.get('battleunit', {
            //         params: {
            //         unittype: _battleUnitFilter.Unittype
            //         }
            //       });
            //       await setResponse(response.data);
            // }
            // else
            // {
            //     const response = await axios.get('battleunit');
            //     await setResponse(response.data);
            // }



            _battleUnitFilter.Unittype = "";
            _battleUnitFilter.ordertype = "";
            // if(response != null)
            // {
                console.log(response)

                //setBattleunites(response.data);
                //battleunites = response;
                setBattleUnitList(response.data);

            // }

            
          } catch (error) {
            console.error('Error:', error);
          }
        };

    async function handleFormSubmit(battleunitfilter: BattleUnitFilter) {
        //await console.log(battleunitfilter);
        
        await applyFilter(battleunitfilter);
    }


    return(
        <Card sytle='margin: 8em !important' >
            <Card.Content>
                <h1>Filters</h1>
                <Formik enableReinitialize initialValues={battleUnitFilter} onSubmit={values => handleFormSubmit(values)}>
                    {({ handleSubmit}) => (
                        <Form onSubmit={handleSubmit}>
                        <MySelectInput options={UnitTypes} placeholder="Unit Type" name='Unittype'  />
                        <MySelectInput options={OrderBySet} placeholder="Order by Type" name='ordertype'  />
                        <Button 
                                floated="right" 
                                positive 
                                type='submit' 
                                content='Submit'
                            />
                        {/* <Button 
                            content="Clear"
                            basic
                            onClick={() => {
                                clearFilter();
                            }}
                        /> */}
                        </Form>
                    )}

                </Formik>

            </Card.Content>
        </Card>
    )
})