import { Button, Container, Menu, MenuItem } from "semantic-ui-react";

export default function NavBar() {
    return(
        <Menu inverted fixed='top'>
            <Container>
                <Menu.Item header>
                    {/* <img src='/assets/logo.png' alt='logo' style={{marginRight: 10}}/> */}
                    List Of Some Units
                </Menu.Item>
                <Menu.Item name='Units' />
                <Menu.Item>
                    {/* <Button positive content='Unused' /> */}
                </Menu.Item>
            </Container>
        </Menu>
    )
}